// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articulos-aws-lambda-primer-paso-mdx": () => import("./../src/pages/articulos/aws-lambda-primer-paso.mdx" /* webpackChunkName: "component---src-pages-articulos-aws-lambda-primer-paso-mdx" */),
  "component---src-pages-articulos-js": () => import("./../src/pages/articulos.js" /* webpackChunkName: "component---src-pages-articulos-js" */),
  "component---src-pages-articulos-mi-primer-articulo-mdx": () => import("./../src/pages/articulos/mi-primer-articulo.mdx" /* webpackChunkName: "component---src-pages-articulos-mi-primer-articulo-mdx" */),
  "component---src-pages-articulos-resaltado-sintaxis-codigo-gatsby-md": () => import("./../src/pages/articulos/resaltado-sintaxis-codigo-gatsby.md" /* webpackChunkName: "component---src-pages-articulos-resaltado-sintaxis-codigo-gatsby-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-template-js": () => import("./../src/templates/categoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../src/templates/tagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

